body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f3f7f9;
}

.container {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

.section {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.section h1 {
  color: #333;
}

.section p {
  color: #666;
}

.coming-soon {
  font-size: 3em;
  /* Increase font size */
  font-weight: bold;
  /* Make it bold */
  animation: jump 0.6s infinite alternate;
}

@keyframes jump {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10px);
  }
}